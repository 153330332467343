@if (data$ | async; as data) {
  <div class="{{ data.class }} step-container">
    <div class="secondary">
      @if (data.image) {
        <picture class="image-container">
          <source [srcset]="data.image.desktop" media="(min-width: 1024px)" />
          <img class="main-image" [src]="data.image.mobile" alt="{{ data.class }} image" />
        </picture>
      }
    </div>
    <div class="main">
      <h1 class="title">{{ data.title }}</h1>
      <h5 class="subtitle">{{ data.subtitle }}</h5>
      @if (data.actions?.length) {
        <div class="actions">
          @for (actionItem of data.actions; track actionItem) {
            @switch (actionItem.buttonType) {
              @case (StepButtonType.NORMAL) {
                <ess-button [buttonType]="'submit'" [class]="'full-width'" (click)="executeAction(actionItem.type)">{{
                  actionItem.title
                }}</ess-button>
              }
              @case (StepButtonType.TEXT) {
                <ess-button [buttonType]="'submit'" [class]="'text'" (click)="executeAction(actionItem.type)">{{
                  actionItem.title
                }}</ess-button>
              }
            }
          }
        </div>
      }
    </div>
    @if (data.hasNext) {
      <div class="navigation"
        ><ess-button [buttonType]="'submit'" [class]="'full-width'" (click)="nextStep()"> Next </ess-button>
      </div>
    }
  </div>
}
