import { ComponentType } from '@angular/cdk/portal';
import { OnboardingSurveyStepComponent } from '@app/features/onboarding-survey/components/onboarding-survey-step.component';
import { StepAction } from './onboarding-survey-step-action.model';

export type OnboardingSurveyStep = OnboardingSurveyStepBaseProps &
  (OnboardingStepGenericProps | OnboardingStepQuestionProps);

type OnboardingSurveyStepBaseProps = {
  class?: string;
  component: ComponentType<OnboardingSurveyStepComponent>;
  hasNext?: boolean;
  hasPrev?: boolean;
  image?: {
    desktop: string;
    mobile: string;
  };
  publishStep?: boolean;
  subtitle?: string;
  title: string;
};

type OnboardingStepQuestionProps = {
  answerOptions: [string, ...string[]];
  group: OnboardingStepGroup;
  required?: boolean;
  type: OnboardingStepType.QUESTION;
  userAnswer?: OnboardingSurveyUserAnswer;
};

type OnboardingStepGenericProps = {
  actions?: StepAction[];
  type: OnboardingStepType.GENERIC;
};

export type OnboardingStepQuestion = OnboardingSurveyStepBaseProps & OnboardingStepQuestionProps;
export type OnboardingStepGeneric = OnboardingSurveyStepBaseProps & OnboardingStepQuestionProps;
export type OnboardingSurveyUserAnswer = string[]; // Only multiselect for now;
export enum OnboardingStepType {
  GENERIC,
  QUESTION,
}

export enum OnboardingStepGroup {
  PREFERENCES,
  CATEGORIES,
  TRAVEL_HABITS,
}

export function isQuestionStep(step: OnboardingSurveyStep): step is OnboardingStepQuestion {
  return step.type === OnboardingStepType.QUESTION;
}
