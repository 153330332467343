import { Injectable } from '@angular/core';
import { OnboardingSurveyStoreConfigService } from './onboarding-survey-store-config.service';
import { OnboardingSurveyState } from '@shared/models/onboarding-survey/onboarding-survey-state.model';
import {
  OnboardingSurveyStep,
  OnboardingSurveyUserAnswer,
} from '@shared/models/onboarding-survey/onboarding-survey-step.model';
import { OnboardingSurveySelectorsService } from './selectors/onboarding-survey-selectors.service';
import { OnboardingSurveyReducersService } from './reducers/onboarding-survey-reducers.service';

@Injectable({ providedIn: 'root' })
export class OnboardingSurveyStoreService extends OnboardingSurveyStoreConfigService {
  constructor(
    selectors: OnboardingSurveySelectorsService,
    private reducers: OnboardingSurveyReducersService,
  ) {
    super(selectors);
  }

  public getState(): OnboardingSurveyState {
    return this.store$.getValue();
  }

  private setCurrentStep(step: number) {
    if (step >= 0 && step < this.getState().stepsData.length) {
      this.setState({ currentStep: step });
    }
  }

  setCurrentStepUserAnswer(answer: OnboardingSurveyUserAnswer) {
    const oldState = this.getState();
    const newState = this.reducers.setCurrentStepUserAnswer(oldState, answer);
    this.setState(newState);
  }

  setUserSelectedTopics(topics: string[]) {
    this.setState({ userSelectedTopics: topics });
  }

  setStepsData(steps: OnboardingSurveyStep[]) {
    this.setState({ stepsData: steps });
  }

  nextStep() {
    this.setCurrentStep(this.getState().currentStep + 1);
  }
  prevStep() {
    this.setCurrentStep(this.getState().currentStep - 1);
  }

  jumpToStep(step: OnboardingSurveyStep) {
    const index = this.getState().stepsData.findIndex(stepData => stepData === step);
    if (index) {
      this.setCurrentStep(index);
    }
  }
}
