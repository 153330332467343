import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '@shared/button/button.component';
import { OnboardingSurveyAnswerComponent } from '../onboarding-survey-answer.component';

@Component({
  selector: 'ess-onboarding-survey-select',
  templateUrl: './onboarding-survey-select.component.html',
  styleUrls: ['./onboarding-survey-select.component.scss'],
  imports: [AsyncPipe, ReactiveFormsModule, FormsModule, ButtonComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingSurveySelectComponent extends OnboardingSurveyAnswerComponent {}
