import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OnboardingSurveyStepComponent } from '../onboarding-survey-step.component';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/button/button.component';
import { OnboardingCategoryInsiders } from '@shared/models/onboarding-survey/onboarding-survey-insiders.model';
import { OnboardingCategoryPictures } from '@shared/models/onboarding-survey/onboarding-survey-categories.model';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective, SwiperSlideDirective } from '@ess-front/shared';

@Component({
  selector: 'ess-onboarding-survey-carousel',
  templateUrl: './onboarding-survey-carousel.component.html',
  styleUrls: ['./onboarding-survey-carousel.component.scss'],
  imports: [AsyncPipe, ButtonComponent, SwiperDirective, SwiperSlideDirective],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingSurveyCarouselComponent extends OnboardingSurveyStepComponent {
  OnboardingCategoryInsiders = OnboardingCategoryInsiders;
  OnboardingCategoryPictures = OnboardingCategoryPictures;

  config: SwiperOptions = {
    spaceBetween: 20,
    pagination: { clickable: true },
    centeredSlides: true,
    slidesPerView: 1,
  };

  userSelectedTopics$ = this.store.userSelectedTopics$;
}
