import { Injectable } from '@angular/core';
import { OnboardingSurveyState } from '@shared/models/onboarding-survey/onboarding-survey-state.model';
import { OnboardingSurveyUserAnswer } from '@shared/models/onboarding-survey/onboarding-survey-step.model';

@Injectable({ providedIn: 'root' })
export class OnboardingSurveyReducersService {
  setCurrentStepUserAnswer(state: OnboardingSurveyState, answer: OnboardingSurveyUserAnswer) {
    const stepsData = state.stepsData.map((stepData, index) => {
      if (index === state.currentStep) {
        return {
          ...stepData,
          userAnswer: answer,
        };
      } else {
        return stepData;
      }
    });
    return { ...state, stepsData };
  }
}
