import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '@shared/button/button.component';
import { OnboardingSurveyAnswerComponent } from '../onboarding-survey-answer.component';
import { OnboardingCategoryPictures } from '@shared/models/onboarding-survey/onboarding-survey-categories.model';
import { filter, take } from 'rxjs';
import { isQuestionStep } from '@shared/models/onboarding-survey/onboarding-survey-step.model';

@Component({
  selector: 'ess-onboarding-survey-cards',
  templateUrl: './onboarding-survey-cards.component.html',
  styleUrls: ['./onboarding-survey-cards.component.scss'],
  imports: [AsyncPipe, ReactiveFormsModule, FormsModule, ButtonComponent, NgClass],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingSurveyCardsComponent extends OnboardingSurveyAnswerComponent {
  onboardingCategoryPictures = OnboardingCategoryPictures;

  nextStep() {
    if (this.stepForm.dirty) {
      this.data$.pipe(filter(isQuestionStep), take(1)).subscribe(data => {
        const selectedOptions = this.stepForm.value.options
          .map((value, index) => (value ? data.answerOptions[index] : null))
          .filter(value => value !== null);
        this.domain.setUserSelectedTopics(selectedOptions);
        super.nextStep();
      });
    } else {
      super.nextStep();
    }
  }
}
