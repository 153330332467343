@if (data$ | async; as data) {
  <div class="{{ data.class }} step-container">
    @if (userSelectedTopics$ | async; as userSelectedTopics) {
      <div class="secondary">
        <swiper-container init="false" essFrontSwiper class="insiders" [config]="config">
          @for (topic of userSelectedTopics; track topic) {
            <swiper-slide essFrontSwiperSlide class="swiper">
              <div class="insider-card">
                <div class="images">
                  <img [src]="OnboardingCategoryInsiders[topic].image" alt="Insider picture" />
                  <img [src]="OnboardingCategoryPictures[topic]" alt="Category picture" />
                </div>
                <div class="info">
                  <h2 class="title">{{ OnboardingCategoryInsiders[topic].name }}</h2>
                  <p class="bio">{{ OnboardingCategoryInsiders[topic].bio }} </p>
                  <div class="category"
                    ><span>{{ topic }}</span></div
                  >
                </div>
              </div>
            </swiper-slide>
          }
        </swiper-container>
      </div>
      <div class="main">
        <h1 class="title">{{ data.title }}</h1>
        <h5 class="subtitle">{{ data.subtitle }}</h5>
      </div>
      @if (data.hasNext) {
        <div class="navigation"
          ><ess-button buttonType="button" [class]="'full-width'" (click)="nextStep()"> Next </ess-button>
        </div>
      }
    }
  </div>
}
