import { Component } from '@angular/core';
import { OnboardingSurveyStepComponent } from './onboarding-survey-step.component';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs';
import { isQuestionStep } from '@shared/models/onboarding-survey/onboarding-survey-step.model';
import { atLeastOneTrueValidator } from '@shared/validators/validators.class';

@Component({
  selector: 'ess-onboarding-survey-answer',
  template: '',
})
export class OnboardingSurveyAnswerComponent extends OnboardingSurveyStepComponent {
  stepForm: FormGroup | FormControl;

  constructor() {
    super();
    this.initForm();
  }

  private initForm() {
    this.data$.pipe(filter(isQuestionStep), take(1)).subscribe(data => {
      this.stepForm = new FormGroup({
        options: new FormArray([], atLeastOneTrueValidator),
      });

      data.answerOptions.forEach(option => {
        const selected = !!data.userAnswer?.find(answer => answer === option);
        const control = new FormControl(selected);
        (this.stepForm.get('options') as FormArray).push(control);
      });
    });
  }

  nextStep(): void {
    this.onSubmit();
  }

  onSubmit() {
    if (this.stepForm.dirty) {
      this.data$.pipe(filter(isQuestionStep), take(1)).subscribe(data => {
        const selectedOptions = this.stepForm.value.options
          .map((value, index) => (value ? data.answerOptions[index] : null))
          .filter(value => value !== null);
        this.domain.setCurrentStepUserAnswer(selectedOptions);
        super.nextStep();
      });
    } else {
      super.nextStep();
    }
  }
}
