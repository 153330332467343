export enum OnboardingCategories {
  ARTS_CULTURE = 'Arts & Culture',
  FASHION_SHOPPING = 'Fashion & Shopping',
  GASTRONOMY = 'Gastronomy',
  HEALTH_WELLNESS = 'Health & Wellness',
  NATURE_OUTDOORS = 'Nature & Outdoors',
  SPORTS_ADVENTURE = 'Sports & Adventure',
}

export const OnboardingCategoryPictures: Record<OnboardingCategories, string> = {
  [OnboardingCategories.ARTS_CULTURE]:
    'https://res.cloudinary.com/thebellhop/image/upload/v1707481497/onboarding/categories/arts_culture.webp',
  [OnboardingCategories.GASTRONOMY]:
    'https://res.cloudinary.com/thebellhop/image/upload/v1707481497/onboarding/categories/gastronomy.webp',
  [OnboardingCategories.HEALTH_WELLNESS]:
    'https://res.cloudinary.com/thebellhop/image/upload/v1707481497/onboarding/categories/nature_outdoors.webp',
  [OnboardingCategories.FASHION_SHOPPING]:
    'https://res.cloudinary.com/thebellhop/image/upload/v1707481497/onboarding/categories/fashion_shopping.webp',
  [OnboardingCategories.NATURE_OUTDOORS]:
    'https://res.cloudinary.com/thebellhop/image/upload/v1707481497/onboarding/categories/health_wellness.webp',
  [OnboardingCategories.SPORTS_ADVENTURE]:
    'https://res.cloudinary.com/thebellhop/image/upload/v1707481497/onboarding/categories/sports_adventure.webp',
};

export const OnboardingCategoryIds: Record<OnboardingCategories, number> = {
  [OnboardingCategories.ARTS_CULTURE]: 28,
  [OnboardingCategories.GASTRONOMY]: 23,
  [OnboardingCategories.HEALTH_WELLNESS]: 27,
  [OnboardingCategories.FASHION_SHOPPING]: 21,
  [OnboardingCategories.NATURE_OUTDOORS]: 29,
  [OnboardingCategories.SPORTS_ADVENTURE]: 25,
};
