import { Component, inject } from '@angular/core';
import { OnboardingSurveyStoreService } from '../store/onboarding-survey-store.service';
import { Observable } from 'rxjs';
import { OnboardingSurveyStep } from '@shared/models/onboarding-survey/onboarding-survey-step.model';
import { OnboardingSurveyDomainService } from '@domain/onboarding-survey-domain.service';
import { StepActionType } from '@shared/models/onboarding-survey/onboarding-survey-step-action-type.model';

@Component({
  selector: 'ess-onboarding-survey-step',
  template: '',
})
export class OnboardingSurveyStepComponent {
  protected readonly store = inject(OnboardingSurveyStoreService);
  protected readonly domain = inject(OnboardingSurveyDomainService);

  data$: Observable<OnboardingSurveyStep> = this.store.currentStepData$;

  nextStep() {
    this.domain.nextStep();
  }

  prevStep() {
    this.domain.prevStep();
  }

  executeAction(actionType: StepActionType) {
    this.domain.executeAction(actionType);
  }
}
