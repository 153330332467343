@if (data$ | async; as data) {
  <div class="step-container">
    <div class="secondary">
      <picture class="image-container">
        <source [srcset]="data.image.desktop" media="(min-width: 1024px)" />
        <img class="main-image" [src]="data.image.mobile" alt="Responsive Image" />
      </picture>
    </div>
    <div class="main">
      <div class="title-container">
        <h1 class="title">{{ data.title }}</h1>
        <h5 class="subtitle">{{ data.subtitle }}</h5>
      </div>
      <div class="answers-container">
        <form [formGroup]="stepForm">
          <div formArrayName="options">
            @for (checkbox of stepForm.get('options')?.controls; track checkbox; let i = $index) {
              <div class="box">
                <label for="question_{{ i + 1 }}">
                  <input id="question_{{ i + 1 }}" type="checkbox" [formControlName]="i" />
                  <span>
                    {{ data.answerOptions[i] }}
                  </span>
                </label>
              </div>
            }
          </div>
        </form>
      </div>
    </div>
    @if (data.hasNext) {
      <div class="navigation">
        <ess-button [buttonType]="'button'" [disabled]="!stepForm.valid" class="full-width" (click)="nextStep()">
          Next
        </ess-button>
      </div>
    }
  </div>
}
