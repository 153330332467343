import { Injectable } from '@angular/core';
import {
  OnboardingStepQuestion,
  OnboardingStepType,
  OnboardingSurveyStep,
} from '@shared/models/onboarding-survey/onboarding-survey-step.model';
import { Observable, combineLatest, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingSurveySelectorsService {
  getCurrentStepData$(
    currentStep$: Observable<Readonly<number>>,
    stepsData$: Observable<Readonly<OnboardingSurveyStep[]>>,
  ): Observable<Readonly<OnboardingSurveyStep>> {
    return combineLatest([currentStep$, stepsData$]).pipe(
      map(([step, stepsData]) => {
        return stepsData[step];
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  getUserAnswers$(
    stepsData$: Observable<Readonly<OnboardingSurveyStep[]>>,
  ): Observable<Readonly<Array<Array<string>>>> {
    return stepsData$.pipe(
      map(steps =>
        steps
          .filter(step => step.type === OnboardingStepType.QUESTION)
          .map((step: OnboardingStepQuestion) => step.userAnswer || []),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
