import { OnboardingCategories } from './onboarding-survey-categories.model';

export type OnboardingInsider = {
  bio: string;
  image: string;
  name: string;
};

export const OnboardingCategoryInsiders: Record<OnboardingCategories, OnboardingInsider> = {
  [OnboardingCategories.ARTS_CULTURE]: {
    name: 'Kissa Castañeda',
    bio: 'Kissa was formerly editor-in-chief of Tatler Singapore and her work has appeared in Travel + Leisure and more. She has lived across Asia as well as travelled to over 50 countries.',
    image: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143476/onboarding/insiders/kissa_castaneda.webp',
  },
  [OnboardingCategories.GASTRONOMY]: {
    name: 'Sorrel Moseley',
    bio: 'Sorrel Moseley-Williams is a freelance journalist and sommelier based in Argentina. Specializing in South America, she covers travel, food and wine for the likes of Condé Nast Traveller.',
    image: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143475/onboarding/insiders/sorrel_moseley.webp',
  },
  [OnboardingCategories.HEALTH_WELLNESS]: {
    name: 'Kelsey Ogletree',
    bio: 'Kelsey Ogletree is a Montana-born writer covering travel and wellness for WSJ, Robb Report, Travel + Leisure and more. Her happiest places include Whitefish Lake and Glacier Park and the Riviera Maya.',
    image: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143477/onboarding/insiders/kelsey_ogletree.webp',
  },
  [OnboardingCategories.FASHION_SHOPPING]: {
    name: 'Lindsey Tramuta',
    bio: 'Lindsey Tramuta is a culture and travel writer for The NYT, CN Traveler and many others. Based in Paris, she is the author of “The New Paris: the People, Places & Ideas Fueling a Movement”.',
    image: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143477/onboarding/insiders/lindsay_tramuta.webp',
  },
  [OnboardingCategories.NATURE_OUTDOORS]: {
    name: 'Jen Murphy',
    bio: 'Jen Murphy splits her time between Boulder, Colorado and Maui. She writes a weekly column for WSJ among others, and is an an avid outdoor sports enthusiast.',
    image: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143475/onboarding/insiders/jen_murphy.webp',
  },
  [OnboardingCategories.SPORTS_ADVENTURE]: {
    name: 'Mike MacEacheran',
    bio: 'Mike MacEacheran is a Scottish freelance writer and guidebook author who regularly contributes to leading travel publications.',
    image: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143476/onboarding/insiders/mike_maceacheran.webp',
  },
};
