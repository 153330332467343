import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OnboardingSurveyStepComponent } from '../onboarding-survey-step.component';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/button/button.component';
import { StepButtonType } from '@shared/models/onboarding-survey/onboarding-survey-step-button-type.model';

@Component({
  selector: 'ess-onboarding-survey-generic',
  templateUrl: './onboarding-survey-generic.component.html',
  styleUrls: ['./onboarding-survey-generic.component.scss'],
  imports: [AsyncPipe, ButtonComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingSurveyGenericComponent extends OnboardingSurveyStepComponent {
  StepButtonType = StepButtonType;
}
