import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable, shareReplay, Subject } from 'rxjs';
import { OnboardingSurveySelectorsService } from './selectors/onboarding-survey-selectors.service';
import { OnboardingSurveyStep } from '@shared/models/onboarding-survey/onboarding-survey-step.model';
import { OnboardingSurveyState } from '@shared/models/onboarding-survey/onboarding-survey-state.model';
import { ONBOARDING_SURVEY_CONFIG } from '../onboarding-survey.config';

@Injectable({ providedIn: 'root' })
export class OnboardingSurveyStoreConfigService implements OnDestroy {
  currentStep$: Observable<number>;
  stepsData$: Observable<OnboardingSurveyStep[]>;
  userSelectedTopics$: Observable<string[]>;
  currentStepData$: Observable<Readonly<OnboardingSurveyStep>>;
  userAnwsers$: Observable<Readonly<Array<Array<string>>>>;

  protected store$: BehaviorSubject<OnboardingSurveyState>;
  private state$: Observable<OnboardingSurveyState>;
  private destroy$ = new Subject<void>();

  constructor(private selectors: OnboardingSurveySelectorsService) {
    const initialState: Readonly<OnboardingSurveyState> = {
      currentStep: 0,
      stepsData: ONBOARDING_SURVEY_CONFIG,
      userSelectedTopics: [],
    };

    this.store$ = new BehaviorSubject<OnboardingSurveyState>(initialState);
    this.state$ = this.store$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.currentStep$ = this.state$.pipe(
      map(state => state.currentStep),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
    this.stepsData$ = this.state$.pipe(
      map(state => state.stepsData),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
    this.userSelectedTopics$ = this.state$.pipe(
      map(state => state.userSelectedTopics),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    this.currentStepData$ = this.selectors.getCurrentStepData$(this.currentStep$, this.stepsData$);
    this.userAnwsers$ = this.selectors.getUserAnswers$(this.stepsData$);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected setState(newState: Partial<OnboardingSurveyState>) {
    const currentState = this.store$.getValue();
    this.store$.next({ ...currentState, ...newState });
  }
}
