import { DestroyRef, inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DESTINATION_STEP, PLAN_STEP, START_TRIP_STEP } from '@app/features/onboarding-survey/onboarding-survey.config';
import { OnboardingSurveyStoreService } from '@app/features/onboarding-survey/store/onboarding-survey-store.service';
import { StepActionType } from '@shared/models/onboarding-survey/onboarding-survey-step-action-type.model';
import {
  isQuestionStep,
  OnboardingStepGroup,
  OnboardingStepQuestion,
  OnboardingSurveyStep,
  OnboardingSurveyUserAnswer,
} from '@shared/models/onboarding-survey/onboarding-survey-step.model';
import { ScheduleCallComponent } from '@shared/schedule-call/schedule-call.component';
import { EMPTY, filter, of, switchMap, take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { RequestTripDomainService } from './request-trip-domain.service';
import { OnboardingCategoryIds } from '@shared/models/onboarding-survey/onboarding-survey-categories.model';
import { UserDomainService } from './user-domain.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from '@shared/models/user/user.model';
import { Brand } from '@ess-front/shared';

@Injectable({
  providedIn: 'root',
})
export class OnboardingSurveyDomainService {
  private readonly userDomainService = inject(UserDomainService);
  private readonly router: Router = inject(Router);
  private readonly store = inject(OnboardingSurveyStoreService);
  private readonly dialog = inject(MatDialog);
  private readonly requestTripDomainService = inject(RequestTripDomainService);
  private readonly destroyRef = inject(DestroyRef);

  private readonly actionVinculation: Record<StepActionType, () => void> = {
    [StepActionType.FINISH]: () => this.finish(),
    [StepActionType.PLAN_TRIP]: () => this.planTrip(),
    [StepActionType.SCHEDULE_CALL]: () => this.scheduleCall(),
    [StepActionType.SKIP]: () => this.skip(),
    [StepActionType.YEARLY_CONSULTATION_LINK]: () => this.goToScheduleCall(),
    [StepActionType.PLAN_TRIP_LINK]: () => this.goToPlanTrip(),
  };

  executeAction(actionType: StepActionType) {
    this.actionVinculation[actionType]();
  }

  initOnboardingCheck() {
    return this.userDomainService
      .getCurrentUser$()
      .pipe(
        filter(Boolean),
        tap(user => {
          if (this.shouldNavigateOnboardingSurvey(this.router.url, user)) {
            this.router.navigate(['/onboarding-survey']);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  nextStep() {
    const { stepsData, currentStep } = this.store.getState();

    of(stepsData)
      .pipe(
        switchMap(stepsData => (stepsData[currentStep].publishStep ? this.submitData$(stepsData) : of(EMPTY))),
        tap(() => {
          this.store.nextStep();
          window.scroll({ top: 0, left: 0 });
        }),
        take(1),
      )
      .subscribe();
  }

  prevStep() {
    window.scroll({ top: 0, left: 0 });
    this.store.prevStep();
  }

  setCurrentStepUserAnswer(answer: OnboardingSurveyUserAnswer) {
    this.store.setCurrentStepUserAnswer(answer);
  }

  setUserSelectedTopics(topics: string[]) {
    this.store.setUserSelectedTopics(topics);
  }

  private submitData$(stepsData: OnboardingSurveyStep[]) {
    const questions: OnboardingStepQuestion[] = stepsData.filter(isQuestionStep);
    const onboardingSurvey = {
      preferences: this.extractPreferences(questions),
      categories: this.extractCategories(questions),
      travelHabits: this.extractTravelHabits(questions),
      isOnboardingDone: true,
    };

    return this.userDomainService.patchUser$(onboardingSurvey);
  }

  private extractPreferences(questions: OnboardingStepQuestion[]): string {
    return questions
      .filter(question => question.group === OnboardingStepGroup.PREFERENCES)
      .map((question: OnboardingStepQuestion) => `<b>${question.title}</b>\n${question.userAnswer.join(', ')}`)
      .join('\n\n');
  }

  private extractCategories(questions: OnboardingStepQuestion[]): number[] {
    return questions
      .find(question => question.group === OnboardingStepGroup.CATEGORIES)
      .userAnswer.map(answer => OnboardingCategoryIds[answer]);
  }

  private extractTravelHabits(questions: OnboardingStepQuestion[]): string {
    return questions
      .filter(question => question.group === OnboardingStepGroup.TRAVEL_HABITS)
      .map(question => `<p><b>${question.title}</b>:\n${question.userAnswer.join(', ')}</p>`)
      .join('\n\n');
  }

  private goToScheduleCall() {
    this.store.jumpToStep(PLAN_STEP);
  }
  private goToPlanTrip() {
    this.store.jumpToStep(DESTINATION_STEP);
  }

  private skip() {
    this.store.jumpToStep(START_TRIP_STEP);
  }

  private planTrip() {
    this.requestTripDomainService
      .openRequestTripModal$()
      .pipe(take(1))
      .subscribe(res => res && this.nextStep());
  }

  private scheduleCall() {
    this.userDomainService
      .getUser$()
      .pipe(take(1))
      .subscribe(user => {
        if (user.memberExperienceManager?.calendarUrl) {
          this.dialog.open(ScheduleCallComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100vh',
            width: '100vw',
            data: { calendarUrl: user.memberExperienceManager?.calendarUrl },
          });
        } else {
          // TODO: Temporary fix for MEM-569. Remove when automatic mem assignation for club members is done
          window.open('https://calendly.com/d/k25h-tsnq/introduction-to-essentialist-membership', '_blank');
        }
      });
  }

  private finish() {
    this.router.navigate(['/members-area']);
  }

  private shouldNavigateOnboardingSurvey(url: string, user: User) {
    return url.includes('members-area') && !user.isOnboardingDone && user.brand === Brand.ESSENTIALIST;
  }
}
