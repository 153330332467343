import { OnboardingSurveyCardsComponent } from '@app/features/onboarding-survey/components/onboarding-survey-cards/onboarding-survey-cards.component';
import { OnboardingSurveyCarouselComponent } from '@app/features/onboarding-survey/components/onboarding-survey-carousel/onboarding-survey-carousel.component';
import { OnboardingSurveyGenericComponent } from '@app/features/onboarding-survey/components/onboarding-survey-generic/onboarding-survey-generic.component';
import { OnboardingSurveySelectComponent } from '@app/features/onboarding-survey/components/onboarding-survey-select/onboarding-survey-select.component';
import { OnboardingCategories } from '@shared/models/onboarding-survey/onboarding-survey-categories.model';
import { StepActionType } from '@shared/models/onboarding-survey/onboarding-survey-step-action-type.model';
import { StepButtonType } from '@shared/models/onboarding-survey/onboarding-survey-step-button-type.model';
import {
  OnboardingStepGroup,
  OnboardingStepType,
  OnboardingSurveyStep,
} from '@shared/models/onboarding-survey/onboarding-survey-step.model';

export const TRAVEL_PASSIONS_STEP: Readonly<OnboardingSurveyStep> = {
  title: 'What are your travel passions?',
  subtitle: 'Choose all that apply',
  answerOptions: [
    OnboardingCategories.ARTS_CULTURE,
    OnboardingCategories.GASTRONOMY,
    OnboardingCategories.HEALTH_WELLNESS,
    OnboardingCategories.FASHION_SHOPPING,
    OnboardingCategories.NATURE_OUTDOORS,
    OnboardingCategories.SPORTS_ADVENTURE,
  ],
  required: true,
  hasNext: true,
  hasPrev: true,
  component: OnboardingSurveyCardsComponent,
  type: OnboardingStepType.QUESTION,
  group: OnboardingStepGroup.CATEGORIES,
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143471/onboarding/desktop/Onboarding-Desktop-4.webp',
    mobile:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143471/onboarding/desktop/Onboarding-Desktop-4.webp',
  },
};

export const INSIDERS_CAROUSEL_STEP: Readonly<OnboardingSurveyStep> = {
  title: 'Meet some of our editors and insiders',
  subtitle: "They'll be behind making each of your trips unique.",
  hasNext: true,
  hasPrev: true,
  component: OnboardingSurveyCarouselComponent,
  type: OnboardingStepType.GENERIC,
};

export const INTRO_STEP: Readonly<OnboardingSurveyStep> = {
  title: "Let's get to know you better...",
  subtitle: "We'll ask you a few questions in order to provide you with the best travel experience",
  component: OnboardingSurveyGenericComponent,
  hasNext: true,
  hasPrev: false,
  type: OnboardingStepType.GENERIC,
  class: 'intro',
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143473/onboarding/desktop/Onboarding-Desktop-1.webp',
    mobile: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143478/onboarding/mobile/Onboarding-Mobile-1.webp',
  },
};

export const HELP_STEP: Readonly<OnboardingSurveyStep> = {
  title: 'How can we help you travel better?',
  subtitle: 'Choose all that apply',
  answerOptions: [
    'Receive fully customized itineraries',
    'Receive recommendations from travel experts',
    'Receive editorial insights',
    'Connect me to local insiders',
    'Plan my yearly travel calendar',
    'Manage all my reservations',
    'Help me with all the logistics',
  ],
  required: true,
  hasNext: true,
  hasPrev: true,
  component: OnboardingSurveySelectComponent,
  type: OnboardingStepType.QUESTION,
  group: OnboardingStepGroup.PREFERENCES,
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143472/onboarding/desktop/Onboarding-Desktop-2.webp',
    mobile:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143472/onboarding/desktop/Onboarding-Desktop-2.webp',
  },
};

export const TRAVEL_STEP: Readonly<OnboardingSurveyStep> = {
  title: 'Travel seamlessly and easily with our personalized trip plans',
  subtitle: "Here's what they look like. Helpful, mobile and always with you.",
  hasNext: true,
  hasPrev: true,
  component: OnboardingSurveyGenericComponent,
  type: OnboardingStepType.GENERIC,
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143472/onboarding/desktop/Onboarding-Desktop-3.webp',
    mobile: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143480/onboarding/mobile/Onboarding-Mobile-3.webp',
  },
  class: 'travel',
};

export const TRAVEL_WITH_STEP: Readonly<OnboardingSurveyStep> = {
  title: 'Tell us who you typically travel with',
  subtitle: 'Choose all that apply',
  answerOptions: ['Couple', 'Family', 'Friends', 'Solo', 'With young children'],
  required: true,
  hasNext: true,
  hasPrev: true,
  component: OnboardingSurveySelectComponent,
  type: OnboardingStepType.QUESTION,
  group: OnboardingStepGroup.PREFERENCES,
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143475/onboarding/desktop/Onboarding-Desktop-6.webp',
    mobile:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143475/onboarding/desktop/Onboarding-Desktop-6.webp',
  },
};

export const TRIP_TYPES_STEP: Readonly<OnboardingSurveyStep> = {
  title: 'What kinds of trips do you usually take',
  subtitle: 'Choose all that apply',
  answerOptions: [
    'Big summer trips',
    'Business trips',
    'Spring break',
    'Weekend Getaways',
    'Thanksgiving travel',
    'Winder/Holiday Season Travel',
    'Remote Work Travel',
  ],
  required: true,
  hasNext: true,
  hasPrev: true,
  component: OnboardingSurveySelectComponent,
  publishStep: true,
  type: OnboardingStepType.QUESTION,
  group: OnboardingStepGroup.TRAVEL_HABITS,
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143472/onboarding/desktop/Onboarding-Desktop-7.webp',
    mobile:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143472/onboarding/desktop/Onboarding-Desktop-7.webp',
  },
};

export const ALL_SET_STEP: Readonly<OnboardingSurveyStep> = {
  title: "You're all set!",
  subtitle:
    "At Essentialist, we truly believe in discovering authentic and personal experiences that deeply connect travelers to what really matters in each destination. And from now on, we're proud to be doing that for you, too.",
  component: OnboardingSurveyGenericComponent,
  hasNext: false,
  hasPrev: false,
  type: OnboardingStepType.GENERIC,
  class: 'all-set',
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143472/onboarding/desktop/Onboarding-Desktop-8.webp',
    mobile: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143479/onboarding/mobile/Onboarding-Mobile-8.webp',
  },
  actions: [
    {
      title: 'Yearly consultation call',
      type: StepActionType.YEARLY_CONSULTATION_LINK,
      buttonType: StepButtonType.NORMAL,
    },
    {
      title: 'Plan your next trip',
      type: StepActionType.PLAN_TRIP_LINK,
      buttonType: StepButtonType.NORMAL,
    },
    {
      title: 'Skip',
      type: StepActionType.SKIP,
      buttonType: StepButtonType.TEXT,
    },
  ],
};

export const PLAN_STEP: Readonly<OnboardingSurveyStep> = {
  title: 'Plan the year ahead',
  subtitle:
    'Talk to your Member Experience Manager to discuss your travel passions and your personalized yearly trip plans.\n\nThis once-a-year call is highly recommended to allow members to get the most out of their membership.',
  component: OnboardingSurveyGenericComponent,
  hasNext: false,
  hasPrev: false,
  type: OnboardingStepType.GENERIC,
  class: 'plan',
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143470/onboarding/desktop/Onboarding-Desktop-9.webp',
    mobile: 'https://res.cloudinary.com/thebellhop/image/upload/v1698143479/onboarding/mobile/Onboarding-Mobile-9.webp',
  },
  actions: [
    {
      title: 'Schedule a call',
      type: StepActionType.SCHEDULE_CALL,
      buttonType: StepButtonType.NORMAL,
    },
    {
      title: 'Later',
      type: StepActionType.SKIP,
      buttonType: StepButtonType.TEXT,
    },
  ],
};

export const DESTINATION_STEP: Readonly<OnboardingSurveyStep> = {
  title: 'Everybody has a destination in mind...',
  subtitle: 'Would you fancy getting starting to plan your first trip? Start organizing your upcoming trip now!',
  component: OnboardingSurveyGenericComponent,
  hasNext: false,
  hasPrev: false,
  type: OnboardingStepType.GENERIC,
  class: 'destination',
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143474/onboarding/desktop/Onboarding-Desktop-10.webp',
    mobile:
      'https://res.cloudinary.com/thebellhop/image/upload/v1698143479/onboarding/mobile/Onboarding-Mobile-10.webp',
  },
  actions: [
    {
      title: 'Start planning',
      type: StepActionType.PLAN_TRIP,
      buttonType: StepButtonType.NORMAL,
    },
    {
      title: 'Later',
      type: StepActionType.SKIP,
      buttonType: StepButtonType.TEXT,
    },
  ],
};

export const START_TRIP_STEP: Readonly<OnboardingSurveyStep> = {
  title: 'Start a trip at anytime!',
  subtitle: "Whenever you want to travel, just click on the 'start a new trip' button to initiate a new trip request!",
  component: OnboardingSurveyGenericComponent,
  hasNext: false,
  hasPrev: false,
  type: OnboardingStepType.GENERIC,
  class: 'start-trip',
  image: {
    desktop:
      'https://res.cloudinary.com/thebellhop/image/upload/v1709026277/onboarding/desktop/Onboarding-Desktop-11.webp',
    mobile:
      'https://res.cloudinary.com/thebellhop/image/upload/v1709026339/onboarding/mobile/Onboarding-Mobile-11.webp',
  },
  actions: [
    {
      title: 'Got it',
      type: StepActionType.FINISH,
      buttonType: StepButtonType.NORMAL,
    },
  ],
};

export const ONBOARDING_SURVEY_CONFIG: OnboardingSurveyStep[] = [
  INTRO_STEP,
  HELP_STEP,
  TRAVEL_STEP,
  TRAVEL_PASSIONS_STEP,
  INSIDERS_CAROUSEL_STEP,
  TRAVEL_WITH_STEP,
  TRIP_TYPES_STEP,
  ALL_SET_STEP,
  PLAN_STEP,
  DESTINATION_STEP,
  START_TRIP_STEP,
];
